import "../../core/src/components/affine/setting-modal/workspace-setting/properties/styles.css.ts.vanilla.css!=!../../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/affine/setting-modal/workspace-setting/properties/styles.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA7VVwW7bMAy95yt4GbAeVDhpk2Yq9iVDD7JE21xlSZDk2t2wfx8spYmTLIEzbCdbovz4HvlE3+snuXFtAT8XAIqC0+KdQ6VxeF5AejJFHmUkazhIq7vWjJGeVGw4LIvi07j83oVI1TuT1kQ0kYNEE9E/L34t7nOG5bwM3vZ/xAtOSGQlxh4xERCaasMoYhsO2QCcUIpMzWHjBijGnVb4mgwrbYy25bDcuGFCa3UTrdlJa+HSany3XdRkkIOxBseN6IUJlIFLIV9rbzujoLhfBUARcErvmxJRsIbqRlPdxJdE9/ARhzfhPzMmqooMskOABZTWKOHHGmrr7yagD7c2O6l5zGqul/MxQaeMJ9RIWrOnAlBZE1mgH3gGsb4IEXGIzHlqp6qOoI6O5/02nbFv6Cttew4NKZUdlOAOAdSaXKCQ3N1QRJY8N7at98JNGeamdMaIFtXX6Dt8uUjaaSGxsVqhP+/E5rrW8x7OU3ud/lNKenKBGxztxbN7S+tHutE6Dks3QLCa1InT8pEzRdsEnqxU+7Gsy0nwy3W5ioIoNc4VO4RpYvF/Snli9+2RVct/MTzS5drmyzWHUXrvd+1aF6lhN8neT6utG3aXD6Alw3aWWG2KI5Uyt/QatYat7ybG8UJRF/Yj46xAFypx8Seyd+vDKiN+uPVjnSoeGk/m9cjCc+371yP1Nz/NQUE/BwAA\"}!../../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var divider = 'l7c6pm7';
export var listHeader = 'l7c6pm1';
export var main = 'l7c6pm0';
export var metaList = 'l7c6pm3';
export var propertyDocCount = 'l7c6pm6';
export var propertyIcon = 'l7c6pm4';
export var propertyName = 'l7c6pm5';
export var propertyNameIconEditable = 'l7c6pmc';
export var propertyRequired = 'l7c6pm9';
export var propertyRow = 'l7c6pm2';
export var propertyRowNamePopupRow = 'l7c6pmb';
export var spacer = 'l7c6pm8';
export var subListHeader = 'l7c6pma';