import "../../core/src/components/workspace-selector/workspace-card/styles.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/workspace-selector/workspace-card/styles.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA7VW227bMAx9z1cIAQY0QBXIadJmLvohw9AHWqZtrbJkSHIuHfrvg2XHUWI7W4HuKTFFUuQheaglj+oHWTHye0ZIgSIvXEw2rDo8zwhJha0kHGOSSfQCkCJXVDgsbUw4KoemEedQxWTb2lSQpkLlMWHksZUk2qRoqIFU1DYm61aqayeFwpgorbAR7EXqiphEjH1rPks40E60YV08XEttYrIDc0cpZJlQSB0eHK2MKMEcqVdYPM8+Zssur7jQOzQ+O14b25hXWpwCT4C/5UbXKr3y6q2G7iLvqHNAcYfK2XMGugIu3DEmbLkNjUZiSDGDWrrrGFpPveXqoirrVYfcDk0m9T4mhUhTVIHBgzcYlK35pakwyJ3QKm5wrEs1KKhXsw6Mj8sZUDbTpozbvxIc/rhji/5MtM56PcKWG0t4nQhOE3wXaO6W6839chXds/toQZgNU/uZggMK3IkdvsydqXH+Si7SGA+ANjCENVl75avu8ankpgEpGsdsiOsnYPtVWyeypt+UQ+XCWQih6Rri64BZt4fuWOHLXGlTgpy/+vzPvTftKwNpp5y1vXzL2cYfZVo5asU7Xg1MKy99d0g/QR26qw5dr7DvCYY1stqioRYlchdM0Uip/JCfD1BKUVlhPW0UwiG1FXDPJXsD1Qib9LUVykeXSM3fguQexwfHE1uXwAT33QTkYEcAYSOArFtAxggukcDf6IYtLsK1u/yi7zuuPV1y+h5zKLhW1CLXKgVzDN0+/R8UbrXFJKX3AQ5Z+OlLsw8Svu6K7d9b/laFg1XYLcdSqCut/q7vn6XuGxzUGE0SX38jTM/6aW2Oa0aBZnKm6o70QErCltGDJQg23OwsLNEJo7FpL8HkQlGJmeus/vUhcgOTSgLHocX44yTRB2oLSJu4TrwU7mmfbwUGlV+Vg5fFFJBJ2LUnCC7S3Q4DuGi6Vkijxc3nS0uK2wvO4N3bpa9TYrWsnc/NtEUZxzp83Y3iPpXt9Y0GJTSb6Hn28Qcy4Jd7dwoAAA==\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var container = 'c1u3lp0';
export var disable = 'c1u3lp1';
export var enableCloudButton = 'c1u3lpa';
export var settingButton = 'c1u3lpb';
export var showOnCardHover = 'c1u3lpc';
export var workspaceActiveStatus = 'c1u3lp7';
export var workspaceInfo = 'c1u3lp4';
export var workspaceInfoSlide = 'c1u3lp3';
export var workspaceInfoSlider = 'c1u3lp2';
export var workspaceInfoTooltip = 'c1u3lp8';
export var workspaceName = 'c1u3lp5';
export var workspaceStatus = 'c1u3lp6';
export var workspaceTitleContainer = 'c1u3lp9';