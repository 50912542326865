import "../../component/src/ui/modal/styles.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../component/src/ui/modal/styles.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA61W62rjOBT+n6c4WyikUAUnTTMddwplF/YFdmFZlrIcW8eJWlnySMqtS999keQ4tnOZduivhHP9vnOTH19oWxgsycK/47ycfd8+T+G/AUBhdBn+AOgKc+G2KST3A4C3AYDTfdU4qt4Gj4cBb88HBHAGlS20KdP4V6Kjv4dscnkFNkdJw2T0dXb1g+SnwiS7IOOrMxhnIeZh6J9CeIzqJ2D80q/j8WDjJLk8LNapxGfy3b0vX/LeZC1gb4PRLksWfCpthRNapVCIDXFvJZQlV3cgw/xlbvRScZZrqU0KKzRDxrAohCLWUpeao4xGIdcrE4rTpudQS6N1sEMlSowI9oswvk1KC4U2azTc3ndgj7wHU1pRINDy97KebakzIaPhD6hI3JJh0b6B14qVv6dcXNhK4jaFQtImsJNirphwVNoUclKOjBc/L60TxZblWjlSrq16X93awP7h6JAVSymZzQ2RerhwZkkXTxExci7UPIUEfhFlpY1D5boBPlDR2rZATn/4xflTV32f/fWJXcyXmchZRq+CzDAZTSfXkFxDMrq9u4ZxdwRYIaT0HCntND8y3AW+eYJzeEYrxzB3YhXZrAStWViG0DimsKQ9yK/H2FkpOP2qnasX8Ai5L5CMJjcWCC19DoVW0g8xQB89TftGWvJhQ/LqFI3Z5/XoPAQ8CeHuo5VsCsdDSNYUNUnhviMY9wWTKFgL7ha7BWu8A80FifnC9XWxBKVQ7Lh+EvW4afQ5ytzf3dUCGNxMqk1jUSdvDNbAYJLUBpneMCtew75m2nAyLNPhkBRaOa+i3mEI8gwtXTVW6xrDNAk3SXqzHa7xaHbfPgo+M0ymVcixP26GJPr5+9gToFdkJG5ZhYraT0FgtUCu1z3fSlfep1bWtoG1QS6WNoXxJELTS+d57C/S6aN3bj5CwQ+GJPTpYFIaaatrjX+7141hD3vSmVbqvR+YWS2XLpTY6SqFSU3VxKi+Mz/1HhTx6+HcvCzY7HBcZkfHZXob6Zq5UCwL52nXlVbOeci51oazzBC+pBB+mJeEjVsIR8xWmFMKlSG2NliFvq7IFFKvg6Dr1Qq/COEPHteDV9QrGCl+5uWtJ5+Fkk/rGtf0ggyXTnvZ3OOJTWgjGYWK/661I/OXcIvfFkJyQ6r91sZAR3wNrcjYOAkBKxeG8nrl9JrV+tPcrEPTfbxFCNYaxctOWbd7Qs3O++7B1O99Ev90Aj4fr3Ufbq7lslT7TQxlebgoUFq6eDr4ahK+/oxWpJztL3HXtb+/fd9IpwX45WOA687uRvixJC4Q4g0BVByGYdfh2wPM/HzEh6tHqDde7bnbT8FuW0ithhYLYmgIWfhcrHXX+8v/Nnj7HyOo/CwTDgAA\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var closeButton = '_1cm6qyje';
export var confirmModalContainer = '_1cm6qyjj';
export var confirmModalContent = '_1cm6qyji';
export var heightVar = 'var(--_1cm6qyj1)';
export var minHeightVar = 'var(--_1cm6qyj2)';
export var modalContent = '_1cm6qyjd';
export var modalContentWrapper = '_1cm6qyjc';
export var modalDescription = '_1cm6qyjg';
export var modalFooter = '_1cm6qyjh';
export var modalHeader = '_1cm6qyjf';
export var modalOverlay = '_1cm6qyjb';
export var modalVTScope = '_1cm6qyj3';
export var promptModalContent = '_1cm6qyjk';
export var widthVar = 'var(--_1cm6qyj0)';