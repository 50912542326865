import "../../core/src/components/affine/affine-error-boundary/error-basic/error-detail.css.ts.vanilla.css!=!../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/affine/affine-error-boundary/error-basic/error-detail.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA7VU227bMAx991cIBQa0DwqUxMlSBf2SYRgUi3bUypIh0XGyof8+SL4mMNJ2wF4si6TIc45ILX5tNil7PTHyJyGksl6hsoYTcfBW1wj7hBCpfKXFhZNcwzkYwkqlcpC1wZnVdWmC57X2qPILzaxBMMhJBgbBBZfQqjBUIZR+aj6CKo7IyZKxb2HfKInHcYtOmAGT1oQt1p6A8ECVobbGffKeLDoOy8jhP6CNGTwKh59AXIiKk10VS1dCSmUKTpbb1lCKM+3iU8Za25Ti4L9P9IcUKKg/2ibAyt5eHtDV8PAzCjCpsW1rjCdXMeIGsT2By7VtODkqKSFKg3BG2rjAJXwjT+skPTgQb5zEhQbLNPs6Zu/1ETXannNvY3OEW+cMYxLuyjpOTsI9UiryXBmgEZqHzBop3OUpXrE1SL36DZws01ZVHUL7sqtVa4xxTWdMGbsC/6GoQ7ZbVdNb3W9aY91fddtVHnQ+9tqYZhPT/DuXO3JVTpW9WKVwhTL0YBFtyUl6zWU7P0TdIbTV2Mxzjb/u0H31IRgBfG9fon5y0urcT9NUma5Mqcyg+ermVnbzTD4o/xxPTVs0/ufWlbMd2gmjIcdh7HtEnU7Dg7G9Bvh8t+OGspw4iwLhcbljEoqnffL+F2t/HcO2BQAA\"}!../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var actionButton = '_5540jv7';
export var actionContainer = '_5540jv6';
export var actionContent = '_5540jv8';
export var arrowIcon = '_5540jv9';
export var errorContainer = '_5540jv1';
export var errorLayout = '_5540jv0';
export var illustration = '_5540jv4';
export var label = '_5540jv2';
export var scrollArea = '_5540jv3';
export var text = '_5540jv5';