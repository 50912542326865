import "../../core/src/components/affine/page-properties/tags-inline-editor.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/affine/page-properties/tags-inline-editor.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA51V227bMAx9z1fwZUCzQoWd2zoF/ZKhGBSJtrXJkiDJjdNh/z5YjmM5l6LZQxKIpA4PD0Xm6We+fFd1rrIfggVGsLbh8BJcg6/wZwbAjTKOwhtzD4SwopAaiVWMY2WUQEeif76d/Z09DUh5vCikt4odKBQK2+0M4i8R0iEP0mjaITe17jx7KUJFIc+yL92xZJZCvrDtBHVxHTUGb+yYYe86S/d9AZ2gLSOaZUJIXVLIulhrvOypOVQsyDfsrMFYCpwp/kCWT2vbwuNRDMeEbIk11qIjTPPKOFKhLKswh69A8nl3W2ERKPQ3Ez4R7zZMjJrDI+Qb20Yc84auUGZPoZJCoJ4Us/pAmtVNaU7F55ltj3oD7Bj/XTrTaEGutb7qiJyaDlBLfSyawuq8ZevIK6YuXcc9v8wbla+ZK6WmQEbTzjiBjoI2OrbBNEFJjaOhMDoQL9+RQj4U2ZkKVkt1oCB1hU6G7fiGE8tllcEx7S1zqMO0BkqT5/6/I7G5dyRi7577ssZX2lmGT69bO6qfZb1xfCqsCWZC49t1Gr8aH2RxINzogDpQ8JZxJDsMe8RIhylZaiID1p4CRx3QXTI768pEn2hv/fwUsz/SXmfZ9oamAdtAPHKjBXOHa7I+x3qGgkk7TsetaU4VWwyKjWIn2N8/1zJn9ncJNCRfrs5zA/DG+U4Fa+QA0I9BXBKNP05zSrJf2oXhjUeRrO27xjgBZL2ilnEZDnEvptlovAe3ovMpVM/NBxbwxVjUrx9F767sisTNo/u4PRfZVMvhfNGsG125eO+DK0ko0oT5ZppwOJ91Zx3/ZP4BulfUHlIHAAA=\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var inlineTagsContainer = '_13zlm1l2';
export var searchInput = '_13zlm1l5';
export var spacer = '_13zlm1lb';
export var tagColorIcon = '_13zlm1ld';
export var tagColorIconWrapper = '_13zlm1lc';
export var tagEditIcon = '_13zlm1la';
export var tagSelectorItem = '_13zlm1l9';
export var tagSelectorTagsScrollContainer = '_13zlm1l8';
export var tagsEditorRoot = '_13zlm1l1';
export var tagsEditorSelectedTags = '_13zlm1l4';
export var tagsEditorTagsSelector = '_13zlm1l6';
export var tagsEditorTagsSelectorHeader = '_13zlm1l7';
export var tagsInlineEditor = '_13zlm1l0';
export var tagsMenu = '_13zlm1l3';