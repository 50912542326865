import "../../core/src/modules/create-workspace/views/dialog.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/modules/create-workspace/views/dialog.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA7VT0W7bMAx8z1fwZcD2oMLOnCxVvka2aIebTQkSnbgd9u+D7Mx1Gq9FB+zJgEgej3fnB/O8e9y5DH5uALyLJORYQ8DWCJ3xuAHoTGiIlTivoSj8cNz82jxMY/k4VjsWFekZNZxN+KyUqWtiVNN79yWBVK514VVdcBDlA3UmPKmxYWwdxy5IzUk07LNssW877rMUfWueNNQtDi8MNeSZH2DZ/3W9P32VpYDVdG3l2r7jVGmM13DwY5c31hI3GvLtK9jin2D3N7CTnonxAng32bBcfFzbZFpqWJFgFzVUyIIhPZcuWAwqGEt9nO8oTfWjCa5nq9ZcWJQjVo7trRsdsTpdzcjz4nrDSlDmI/YfVed7H4XqtJQFWZYXXcjKKcmUfZp1nFSZ1317P4OlifjRFN7xv2MZvalQlSgXRF4QOrxPaIhv01k1wgzqKsf2cLiR4PF/bJzRzYj+x4li/5dM3ukzGo5s3wjsS45MGV3bC04xFnGdTr8cQJiil03tlCYVnpElamDHKXi/AaoT1iHEBAAA\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var affineCloudWrapper = 'az595o4';
export var avatarWrapper = 'az595o2';
export var card = 'az595o5';
export var cardDescription = 'az595o8';
export var cardText = 'az595o6';
export var cardTitle = 'az595o7';
export var cloudSvgContainer = 'az595oa';
export var cloudTips = 'az595o9';
export var header = 'az595o0';
export var subTitle = 'az595o1';
export var workspaceNameWrapper = 'az595o3';