import "../../core/src/components/page-list/collections/collection-list-item.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/page-list/collections/collection-list-item.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA8VW247bIBB9z1eglVbaSEvkXJsStf9RVX0gMLbpYrAAb7Kt9t8rwPbixMk21Up9i8cw58yZ45nMeGUzYTL0e4IQF7aW9IWgXMJxN0GIaakNQc/UPGBM81wowA6ODtdGVNS84HBg6o+WIIrSEbRe1eGqT4FtaYR6IijzkYPgriRonmX3/pFKUSgsHFSWIOsMOFb6uDNUWeGEVgTtKXsqjG4Uj0gomy3sI9I1ZcK9hCd/RT+DyaU+EFQKzkEF6o2xnjuHnDbSRQJSYlZSVQDpcuwmr5NZqwEpfaKgxCnwiQbhYF/8W4bvnDqKmRTsie4lfHGmgR8hYUen1kI5MMmleXjfybdaR/n22nAw2FAuGutFi+GzDg1UZBBzp/wvM8e5kBL4NML5ZlHuNRycr0A17ZtwsKJH3DZyuWlJVUJ1sUWM9cUtQnG17hpqQFInniGmMoVQWELuCMLbmKvToXOJbpwUCghSWoVbNeVcqKK9to1oUXduaFEkunccyB5ybSD2QSsHyhF0d7cbMKN7q2XjAobTNUHrSCDYMdemIvGnpA6+PeB1dh/0iDRSf6+GhbSPqavjqwtefsd5taQMSi154r8ztywiZpu6p5e4P1J4TL+CdzQMxkmV7Dt1ija/mC0JClWcIISkb5Rn639LM0Zx2VLsTDpGuPuE0VdEcmGsw6wUkkf/Djw33wwsHm5IOnrBRPzt4MIyPUJQhta3fdsFrbuB0Odcjc/vCxnOZ3OulcNW/IITu4V4iZfplFuPY4WkXBhg0eVMy6ZSZywitqPG7YYqRFkHE4ZRyR78JEAYbVb1cdrhhJ4hdCiFA2xrysKAOBha+3BjwWALEpjr5kZPfhNtNrIwrkpgq2l/5tC6apNlV0gkNMPGfIMEKUVthX2TxoLMkx3Yk/10kezFxWyBacWHq/lqZUf7l6r+cxXb27z5vhPHyxdMq7ToE4/3dD5/HJ1Wu5uakTj+ZOV163TwP6mnTf8v7ZGR4SPeHKlJTqvr6e9vov+zsU7kHr/d1gEMFL/aWfZxGHF44TBqN+N/aF//ACqBugc7CwAA\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var actionsCellWrapper = 'dms0irb';
export var dateCell = 'dms0ira';
export var dndCell = 'dms0ir2';
export var dragPageItemOverlay = 'dms0ir1';
export var iconCell = 'dms0ir8';
export var operationsCell = 'dms0irc';
export var root = 'dms0ir0';
export var selectionCell = 'dms0ir4';
export var tagsCell = 'dms0ir9';
export var titleCell = 'dms0ir5';
export var titleCellMain = 'dms0ir6';
export var titleCellPreview = 'dms0ir7';
export var titleIconsWrapper = 'dms0ir3';