import "../../core/src/components/page-list/tags/tag-list-item.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/page-list/tags/tag-list-item.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA8VWbW/bIBD+3l+BKlVqpBI5r8uItv8xTdNE4GyzEvAAN+mm/fcJsBPs2M1aVdq3+HLcPffcwx3T77MF/DxSnqHfNwhxYStJnwnKJRy3NwgxLbUh6Imae4xpngsF2MHR4cqIPTXPODhMvGsJoigdQatlFY76ENiWRqhHgjJvOQjuSoJmWXbnP6kUhcLCwd4SZJ0Bx0pvd4YqK5zQiqAdZY+F0bXiMRPKpnP7gHRFmXDP4csf0U9gcqkPBJWCc1ABem2sx84hp7V0EYCUmJVUFUDaGNubPzfTEwuk9KECF/3UPRaC46n8NMZXTh3FTAr2SHcSPjlTw7cQsoVUaaEcmM6xWfBoSVyuIok7bTgYbCgXtfXURfNFnzpcMojR0xrG0eNcSAl8EtP5llHumez470HVzT/BcU+PuGnnYt2A2gvV2ubRlpQ3D+VVum2sAUmdeIIYzBRCYQm5IwhvYrSWiVYtunZSKCBIaRVOVZRzoYrm2Cbmi9xzQ4si4f6Mguwg1wZiN7RyoBxBt7fbDja6s1rWLmRxuiJoFSEEYeba7En8KamDL/d4ld0FTiKQVOnLbinNZ6rv+NeIqq8osJKUQaklT3R4oZh5zNmEPsFL7kGE8JDeh6ssBvmkXJ661c83G42XGIUqLnKEsGfY09VbAw3BXDQwW7kOgT5faPQZkVwY6zArheRRyR31zdY9uYczkg4eMRHDpndkkToRtHrdTS9o1Y6HJOZyeKqPxLic2LlWDlvxC3rSC/YSL7qTb/UP2WIOR43bptVmDYWdycKoZPf+/iOM1svqOGlBhg4hdCiFA2wrysJYOBhaeXNtwWALEphrp0UCch2FNbAwXizW7icnn0OjonWWvQAjrMlzHpBSVFbYMyMWZJ7S39PHstfLD6PAR5e0BaYV767pF6vcUQuTYSm8T5VJPZvXafO6EoeJEEyrtPxeYQmgj+8H6GhH8Yw2JrkLvRXYLtjO+ykBTv8v8IGx4S1eIalS+vUlBexeVcCP2jqRewTN/g7pQPEr/WXvl4VpWe8VDkN3PfzcTRLH8d/umt6joP1+M66BqwVpwp6YNoMPy+aFc1HEX9NA8hsgDAAA\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var actionsCellWrapper = '_13eqxadb';
export var dateCell = '_13eqxada';
export var dndCell = '_13eqxad2';
export var dragPageItemOverlay = '_13eqxad1';
export var iconCell = '_13eqxad8';
export var operationsCell = '_13eqxadc';
export var root = '_13eqxad0';
export var selectionCell = '_13eqxad4';
export var tagIndicator = '_13eqxade';
export var tagIndicatorWrapper = '_13eqxadd';
export var tagsCell = '_13eqxad9';
export var titleCell = '_13eqxad5';
export var titleCellMain = '_13eqxad6';
export var titleCellPreview = '_13eqxad7';
export var titleIconsWrapper = '_13eqxad3';