import "../../core/src/components/affine/empty/style.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/affine/empty/style.css.ts.vanilla.css\",\"source\":\"Ll8xdzF6MmxkMCB7CiAgd2lkdGg6IDEwMCU7CiAgZGlzcGxheTogZmxleDsKICBmbGV4LWRpcmVjdGlvbjogY29sdW1uOwogIGFsaWduLWl0ZW1zOiBjZW50ZXI7CiAganVzdGlmeS1jb250ZW50OiBjZW50ZXI7CiAgZ2FwOiAxNnB4OwogIHBhZGRpbmctYm90dG9tOiAzNXB4Owp9Ci5fMXcxejJsZDEgewogIG1heC13aWR0aDogMTAwJTsKICB3aWR0aDogMzAwcHg7Cn0KLl8xdzF6MmxkMiB7CiAgdGV4dC1hbGlnbjogY2VudGVyOwogIGZvbnQtc2l6ZTogMTVweDsKICBsaW5lLWhlaWdodDogMjRweDsKICBmb250LXdlaWdodDogNTAwOwogIGNvbG9yOiB2YXIoLS1hZmZpbmUtdGV4dC1wcmltYXJ5KTsKICBtYXJnaW4tYm90dG9tOiA0cHg7Cn0KLl8xdzF6MmxkMyB7CiAgdGV4dC1hbGlnbjogY2VudGVyOwogIGZvbnQtc2l6ZTogMTRweDsKICBmb250LXdlaWdodDogNDAwOwogIGxpbmUtaGVpZ2h0OiAyMnB4OwogIGNvbG9yOiB2YXIoLS1hZmZpbmUtdGV4dC1zZWNvbmRhcnkpOwogIG1hcmdpbi1ib3R0b206IDA7CiAgbWF4LXdpZHRoOiAzMDBweDsKfQouXzF3MXoybGQ0IHsKICBwb3NpdGlvbjogYWJzb2x1dGU7CiAgdG9wOiA1MCU7CiAgbGVmdDogNTAlOwogIHRyYW5zZm9ybTogdHJhbnNsYXRlKC01MCUsIC01MCUpOwp9Ci5fMXcxejJsZDUgewogIGRpc3BsYXk6IGZsZXg7CiAgZ2FwOiAxNnB4Owp9Ci5fMXcxejJsZDcgewogIHBhZGRpbmc6IDhweCAxOHB4OwogIGhlaWdodDogYXV0bzsKICBmb250LXdlaWdodDogNjAwOwp9Ci5fMXcxejJsZDggewogIHBhZGRpbmc6IDAgNHB4Owp9Ci5fMXcxejJsZDkgewogIHBhZGRpbmc6IDAgNHB4Owp9\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var absoluteCenter = '_1w1z2ld4';
export var actionButton = '_1w1z2ld6';
export var actionContent = '_1w1z2ld8';
export var actionGroup = '_1w1z2ld5';
export var description = '_1w1z2ld3';
export var illustration = '_1w1z2ld1';
export var mobileActionButton = '_1w1z2ld7';
export var mobileActionContent = '_1w1z2ld9';
export var root = '_1w1z2ld0';
export var title = '_1w1z2ld2';