import "../../core/src/components/affine/setting-modal/workspace-setting/new-workspace-setting-detail/style.css.ts.vanilla.css!=!../../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/affine/setting-modal/workspace-setting/new-workspace-setting-detail/style.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA7VXYW/bLBD+3l+BJr1SK5UIp0m20Z/x/oAJm7NNSwABaZxN++8TYMfYcdpt7b5EMhzH3XPPPVxW37bNs2dPBP24QYgLZyQ7UVRL6B5vEGJSNAoLD3uXFjEoHjb2zDZCYa8NRcXadI83P29Wva8i+orWjdVHiorHN31XoDzYzLOE2lO0JlPX6+j6KLhvKSoI+e8vPMeY1xsTTRsWEiDpI4Z8tGEp/OYXP+QXb3fJvgXRtH78LrXlYLFlXBwcRdsUntFOeKEVRRYk8+IFwmp1sE5biowWQ3zxetdaoZ4pIpPbV1w4VkqIUQxHOdTsIH26I3rB8ALKO4qUVjBxQFv9AhatKrYHy7CotIqZGrBLlf+Qk9fPvBNHVjotDx4mxU8pI/R0cF7UJ1xp5UH5nABXeFGy6rmx+qA4rrQMyNqmZLc7co92xT3aPdwjstreBdPvWCgOXU/p3vqF2VuMWV0LBfjYCg/RttbKYye+w8C2EZpNjkLFZHUbuIww2m1MhzAqtqa7W0wmtEXY8NB5HPMZ1nLvzjC11CmhkrUMLdkKzkGdPY0bIKUwTriwFXPBzrAKAr6pJ5bTNpJV0GoZ6hb3RwiOQ3XJhNPbGOByc2jDKuFPFJHVZrlzMz+Ustr3xDrj9OnT42X6A8+G72VGJeUhEZtwIcnbVekAu9RH4HkQu2X5/F0uBvv/PbP+GryxSA4qrTizpwzhqF+9lhnGuVANRV96ORsJOHEW1zt3lyfwOVVj8LBZbMQk9HnDzDxnnWSs2E9DTc4oKkyHnJaCz86mu0b7CzSjPnJhoUpFq7Q87NUizpGzuAR/hMDyMc+v0zxJyBQRVMyVqIfwIoYZoxYVJbutF88fr4EWTS5wOoP+ZUr3r1Rpf0slcx7rGvuTgbvov++RUnuv9xTtpsdYLgfrLbmS3nWI54/T7KEeqNEv2gTj/PkuFyeDj1GlV9nu9hO2lysDKjCgF40FPZvRdzxbpRzevO2VRr7sjY+BQEY69RRez6Yy/nbcURP+UID+ReRkTvl+BOm/0xR0fiOKpQGISZm7qP9N8rOe207jbn5rWL0YOv9qhJlqxm72IKxNdx5y35DhVKAHMh9h/hywS5YvVPkKI0YQ2/dXDvamZU64LJKF6XsiZ5uZPgoVYs/jEtMXPxngV/+BjIef5kkN78+7cvj5C022szPNDQAA\"}!../../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var arrowRight = '_5gktajj';
export var avatarWrapper = '_5gktaj3';
export var fakeWrapper = '_5gktaj5';
export var goUpgrade = '_5gktajh';
export var goUpgradeWrapper = '_5gktaji';
export var iconButton = '_5gktaje';
export var label = '_5gktajf';
export var labelWrapper = '_5gktaj2';
export var memberContainer = '_5gktaja';
export var memberEmail = '_5gktajd';
export var memberList = '_5gktaj8';
export var memberListItem = '_5gktaj9';
export var memberName = '_5gktajc';
export var membersFallback = '_5gktaj6';
export var membersPanel = '_5gktaj7';
export var profileHandlerWrapper = '_5gktaj1';
export var profileWrapper = '_5gktaj0';
export var roleOrStatus = '_5gktajb';
export var urlButton = '_5gktaj4';
export var workspaceLabel = '_5gktajg';