import "../../core/src/components/affine/setting-modal/general-setting/experimental-features/index.css.ts.vanilla.css!=!../../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../core/src/components/affine/setting-modal/general-setting/experimental-features/index.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA6VUzY6bMBC+5yl8qdQ9eEWyNE2dh6kMHmC6xkb2ENhWfffKZreAN6wS7SmKZ/g838/48ecpPyoaM/Znx1hnPRJaI5gsvNU9wXnHmELfafkiWKVhDAfhlyt0UE7NpdV9a0KlAawbEmyfZV/Ou7+7x1f4fYSvrCHu8TcIdpHuK+eyqtAAj+cNzx/Ob03DK84xy8JZK12NhheWyLaC5aduXKIfInrSdEyanmJTIcvn2tneKF5abV0yyaJM4Aile5n65tG25h/9w51i/eo9YRUuMASGBCvBELhQqmUn2D7vIkInlUJTB1Wng8I6BY47qbD3giVE80j0GjtwzrqUz0rqGeXb5FgYvnZ2EGwfjUDD/3t8WN97jF+84y811oYjQeuXDD8gnxj5dJhYR03y9Z3fr9+5rXkEOSQop+somyPOX/74HGffyRJ4ATQAxPEGVNS8Xx85r0+yGddsJhiJeyitUev8JsImuSnu1XKD5ZTdbA1eboZyc9pbts1ewFU6xLNBpSYNI+BcAK2x8+hXy8kHKJ6ReGEjx7e/OuCXWrYhJMtCYUduHUbTLuFhKKX+wC8V2a6Kt6fkFt53CRkNScyG5ZOpoSLBZE92kRI35Sw8Cv8ANci62iUGAAA=\"}!../../../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var arrowRightIcon = '_846dtxe';
export var description = '_846dtxc';
export var feedback = '_846dtxd';
export var promptArt = '_846dtx2';
export var promptDisclaimer = '_846dtx6';
export var promptDisclaimerConfirm = '_846dtx8';
export var promptRoot = '_846dtx0';
export var promptTitle = '_846dtx1';
export var promptWarning = '_846dtx3';
export var promptWarningTitle = '_846dtx4';
export var rowContainer = '_846dtxb';
export var settingsContainer = '_846dtx7';
export var spacer = '_846dtx5';
export var subHeader = '_846dtxa';
export var switchRow = '_846dtx9';